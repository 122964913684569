import React from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatMoney, addMoneyWithCurrency, multiplyMoneyWithCurrency } from '../../helpers';
import { MoneyType } from '../../propTypes';

const AmountToCollectWithDebt = ({ row }) => {
  const { amountWithIva, invoiceDebt, offer } = row;
  const debt = invoiceDebt?.debt;
  const retentionRate = offer?.retentionRate;
  const financedAmount = multiplyMoneyWithCurrency(amountWithIva, ((100 - retentionRate) / 100));
  const totalAmount = addMoneyWithCurrency(financedAmount, debt || 0);

  return (
    <Stack
      width="100%"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Typography
        variant="body2"
        width="inherit"
        marginBottom={0.5}
      >
        {totalAmount ? formatMoney(totalAmount, true) : ''}
      </Typography>
      <Typography
        variant="caption"
        width="inherit"
        fontSize={10}
      >
        Fin: {financedAmount ? formatMoney(financedAmount, true) : ''}
      </Typography>
      <Typography
        variant="caption"
        width="inherit"
        fontSize={10}
      >
        Mora: {debt ? formatMoney(debt, true) : 0}
      </Typography>
    </Stack>
  );
};

AmountToCollectWithDebt.propTypes = {
  row: PropTypes.shape({
    amountWithIva: MoneyType.isRequired,
    invoiceDebt: {
      debt: MoneyType,
    },
    offer: {
      retentionRate: PropTypes.number,
    },
  }).isRequired,
};

export default AmountToCollectWithDebt;
