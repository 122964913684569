import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ProgressiveImageBox from '../boxes/ProgressiveImageBox';

const SelectableImageCard = ({ item, onClick, isSelected, sx, ...props }) => (
  <Card
    sx={{
      p: 2,
      height: 120,
      width: 150,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: isSelected ? '2px solid' : 'none',
      borderColor: isSelected ? 'primary.main' : 'none',
      cursor: 'pointer',
      ...sx,
    }}
    onClick={onClick}
    {...props}
  >
    <ProgressiveImageBox src={item.imageUrl} alt={item.label} />
  </Card>
);

SelectableImageCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

SelectableImageCard.defaultProps = {
  onClick: null,
  isSelected: false,
  sx: null,
};

export default SelectableImageCard;
