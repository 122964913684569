import React from 'react';
import { PropTypes } from 'prop-types';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { formatMoney } from '../../helpers';

const SummaryCard = ({ title, subtitle, isMoney, isWarning, loading }) => (
  <Grid
    container
    sx={{
      bgcolor: 'white',
      borderRadius: 2,
    }}
    padding={1.5}
  >
    {loading ? (
      <Grid item container xs={12}>
        <CircularProgress />
      </Grid>
    ) : (
      <>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            align="center"
            color={isWarning ? 'primary' : ''}
          >
            {isMoney ? `$${formatMoney(title)}` : title}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={0.5}>
          <Typography variant="body1" align="center">
            {subtitle}
          </Typography>
        </Grid>
      </>
    )}
  </Grid>
);

SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  isMoney: PropTypes.bool,
  isWarning: PropTypes.bool,
  loading: PropTypes.bool,
};

SummaryCard.defaultProps = {
  isMoney: false,
  isWarning: false,
  loading: false,
};

export default SummaryCard;
