import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link as MuiLink, Stack, Typography } from '@mui/material';

const LinkCell = ({ to, label, color, caption }) => (
  <Stack alignItems="flex-start" justifyContent="center">
    <MuiLink
      component={Link}
      to={to}
      color={color}
      variant="body2"
      align="left"
      underline="hover"
    >
      {label}
    </MuiLink>
    {!!caption && <Typography variant="subtitle1">{caption}</Typography>}
  </Stack>
);

LinkCell.propTypes = {
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

LinkCell.defaultProps = {
  caption: null,
  color: 'primary',
};

export default LinkCell;
